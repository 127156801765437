$(function () {

    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }


    /* ==== init ==== */
    $('.lazy-load').Lazy({
        effect: 'fadeIn',
        effectTime: 300
    });

    /* === Add aria labels to owl carousel buttons === */
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });


    /* === Scrollable header === */
    var position = $(window).scrollTop(); 

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if(scroll > position) {
            $('.header__container').addClass('header--bg');
            
        } 

        position = scroll;

        // Hide at top
        if ($(document).scrollTop() < 130) {
            $('.header__container').removeClass('header--bg');
        }
    });



    /* ==== Mobile navigation ==== */
    menu_trigger = $('.menu-trigger');
    nav = $('nav');

    menu_trigger.click(function () {
        $(this).toggleClass('menu-trigger--active');
        nav.toggleClass('nav--active');
        $('body').toggleClass('body--nav-open');
    });

    $(document).click(function (e) {
        if ((! menu_trigger.is(e.target))) {
            menu_trigger.removeClass('menu-trigger--active');
            nav.removeClass('nav--active');
            $('body').removeClass('body--nav-open'); 
        }

        if ((!$('.search-icon, .search-container').is(e.target))) {
            $('.search-container').removeClass('search-container--active');
        }
    });

    /* === Search === */
    $('.search-icon').click(function(){
        $('.search-container').toggleClass('search-container--active');
        setTimeout(function() {
            $('.search-container__input').focus();
        }, 300);
    });

    $('.search-container').click(function(event) {
        event.stopPropagation();
    });

    /* === Match image height === */
    $(window).on("load", function() {
        $('.text-block__bg').each(function(i, obj) {
            if ($(window).width() > 748) {
                $(this).closest('.row').find('img').css('height', $(this).outerHeight());
            }
        });
    });


    /* ==== Cookie notice ==== */
    var cookie = $('.cookie-notice');

    if (Cookies.get('s3cookie')) {
        cookie.hide();
    } else {
        cookie.show();
        $('.cookie-notice__close').click(function () {
            Cookies.set('s3cookie', 'accepted', {
                expires: 365
            });
            cookie.hide();
        });
    }

});

$(window).on('load', function () {

    // Remove loading window
    setTimeout(function () {
        $('.loading-overlay').addClass('loading-overlay--hide');
        $('.homepage').addClass('homepage--active');
        $('main').addClass('main--active');
    }, 500);

});